@media screen and (min-width: 800px) {
  .page-main {
    /* padding: 0% 35% !important; */
  }
  .titulo-main {
    font-size: 18px !important;
  }
  .sub-titulo-main {
    color: #696666 !important;
    font-size: 14px !important;
  }

  .title-page{
    color: #305b8e;
    font-weight: 900;
    font-size: 30px !important;
  }
}

.title-page{
  color: #305b8e;
  font-weight: 900;
  font-size: 20px;
}

.label-fecha-emision {
  margin-bottom: 15px !important;
}

.background-image {
  --background: none;
  background: url(../../../public/assets/images/fondo_diris.jpg) 0 0/100% 100%
    no-repeat;
}

.card-main {
  background: #ffffff7d !important;
}

.contend-titulo {
  background: #305b8e;
  border-radius: 20px;
  margin: 0px 25px;
  padding: 4px 10px;
  margin-bottom: 5px;
}

.titulo-main {
  color: #fff;
  font-size: 15px;
}

.sub-titulo-main {
  color: #696666 !important;
  font-size: 12px;
}


.salir{
  float: right;
}