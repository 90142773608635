.card {
  background: #00acc1;
  color: #fff;
}

.titulo-operacion {
  color: #fff !important;
}

.contend-item {
  min-height: 60px !important;
}

.img-operacion {
  min-height: 185px !important;
}
