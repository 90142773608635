@media screen and (min-width: 800px) {
  .page-votaciones {
    /* padding: 0% 20% !important; */
  }
  .titulo-voto {
    min-height: auto !important;
  }
}

.buttom-back {
  margin-bottom: 10%;
}

.footer-elegido {
  color: #0994a9a6 !important;
  /* background-color: #0994a9a6; */
  width: 100% !important;
  margin: 0 !important;
  padding: 2%;
  border-radius: 2px;
  font-weight: bold;
  min-height: 60px !important;
}

.footer-no-elegido {
  color: #d50000 !important;
  /* background-color: rgb(243, 237, 237); */
  width: 100% !important;
  margin: 0 !important;
  padding: 2%;
  border-radius: 2px;
  font-weight: bold;
  min-height: 60px !important;
}

.titulo-voto {
  background-color: #305b8e;
  margin: 0 !important;
  color: #fff !important;
  padding: 3% 2%;
  border-radius: 5px;
  min-height: 70px;
}

.card-warning {
  background-color: #9c9be852;
  min-height: 305px;
}

.card-success {
  background-color: #abefa245;
  min-height: 305px;
}


.text-titulo-pagina {
  background: #305b8e;
  color: #fff;
  padding: 4px 10px;
  border-radius: 15px;
  font-weight: bold;
  margin: 0 15px;
}

.text-avatar {
  font-size: 10px;
}

.button-voto-salud{
  font-size: 10px;
}

.tipo-capacitacion{
  margin: 0 !important;
  color: #fff;
  font-size: 12px;
  background-color: #43a0a7;
  padding: 2px 10px;
}


.nombreLista{
  background-color: #ffbe07 !important;
  padding: 12px 5px;
  opacity: 1;
  transition: 0.3s;
  cursor: pointer;
}
.nombreListaVoto{
  background-color: #ede2c4 !important;
  padding: 12px 5px;
  opacity: 1;
  transition: 0.3s;
  cursor: pointer;
}

.nombreListaVoto:hover {
  opacity: 0.6;
  padding: 15px 5px;
}

.nombreLista:hover {
  opacity: 0.6;
  padding: 15px 5px;
}


.fotoLista{
  opacity: 1;
  transition: 0.3s;
  cursor: pointer;
}

.fotoLista:hover {
  opacity: 0.6;
  padding: 5px 2px;
}

.card-candidato {
  border: 1px solid #388fff;
}

.nombre-candidato {
  font-size: 12px !important;
  margin: 0px !important;
}

.cargo-candidato {
  font-size: 12px !important;
  margin: 0px !important;
}
.titulo-candidatos {
  color: #305b8e;
}

.content-candidato{
  padding: 0px 5px !important;
}