@media screen and (min-width: 800px) {
    .page-resultados {
      /* padding: 0% 20% !important; */
    }
  }

.titulo-resultado{
    color: #fff !important;
    background-color: rgb(21 187 212) !important;
    width: 100% !important;
    margin: 0 !important;
    padding: 2%;
    border-radius: 10px;
    font-weight: bold;
    font-size: 20px;
}

.texto-resultado{
    font-size: 12px !important;
}

.resultado-item-foto{
    margin-right: 10px !important;
}


/* .content-titulo-pagina {
  padding-top: 40px !important;
} */

.text-titulo-pagina {
  background: #305b8e;
  color: #fff;
  padding: 4px 10px;
  border-radius: 15px;
  font-weight: bold;
  margin: 0 15px;
}

.text-avatar {
  font-size: 10px;
}
