@media screen and (min-width: 800px) {
  .page-operaciones {
    /* padding: 0% 20% !important; */
  }
}

/* .content-titulo-pagina {
  padding-top: 40px !important;
} */

.text-titulo-pagina {
  background: #305b8e;
  color: #fff;
  padding: 4px 10px;
  border-radius: 15px;
  font-weight: bold;
  margin: 0 15px;
}

.text-avatar{
  font-size: 10px;
}

